@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

business-group-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;

  b {
    visibility: hidden;
  }

  i {
    font-size: 0.95rem;
    line-height: 1;
    text-align: center;
  }

  &[access] {
    cursor: help;
    text-align: center;
    color: $white;
    border-radius: 26px;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.75);

    b {
      visibility: visible;
    }
  }

  &[access="1"] {
    background-color: get-color(success);
  }

  &[access="0"] {
    background-color: get-color(alert);
  }

  &.absolute {
    position: absolute;
  }

  &.top-right {
    top: 3px;
    right: 3px;
  }
}

.business-group-icon-content {
  transition: opacity 200ms ease-out;
  position: absolute;
  top: -99999px;
  left: -99999px;
  opacity: 0;
  z-index: -1;
  background-color: $tooltip-background-color;
  color: $tooltip-color;
  max-width: $tooltip-max-width;
  padding: $tooltip-padding;
  border-radius: $tooltip-radius;
  font-size: $tooltip-font-size;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;

  &[data-ypos="bottom"]>span {
    @include css-triangle(6px, $tooltip-background-color, up);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-ypos="top"]>span {
    @include css-triangle(6px, $tooltip-background-color, down);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}